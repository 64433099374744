.section {
  padding: 24px 50px;
  background: radial-gradient(get-color('neutral', 'gray'), get-color('neutral', 'dark'));
  box-shadow: 2px 2px 10px get-color('theme', 'primary');

  h2 {
    font-family: $font-secondary;
    font-weight: 900px;
    line-height: 75px;
    text-shadow: 1px 1px 5px get-color('theme', 'primary');
  }

  h3 {
    font-family: $font-secondary;
    text-shadow: 1px 1px 5px get-color('theme', 'primary');
  }

  .service-card {
    margin: 0 auto;
    box-shadow: 2px 2px 2px get-color('theme', 'primary');
    background: radial-gradient(get-color('neutral', 'light'), get-color('neutral', 'gray'));
    border-radius: 10px;
    overflow: hidden;
  
    img {
      border-radius: 10px 10px 0px 0px;
    }
  
    .ant-card-actions {
      border-radius: inherit;
    }
  }
}