// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

/*-----------------------------*\
  SETTINGS - FONTS
\*-----------------------------*/

// Google Fonts
@import url('https://fonts.googleapis.com/css?family=Poppins|Montserrat');

$font-sizes: (
  "larger": 26pt,
  "large": 22pt,
  "base": 18pt,
  "small": 16pt,
  "smaller": 14pt,
  "smallest": 10pt,
) !default;

$font-primary: "Poppins", sans-serif !default;
$font-secondary: "Montserrat", sans-serif !default;
$font-icon: "icomoon" !default;
