$colors: (
  'theme': (
    'primary': #008bff,
    'primary-dark': #03608b,
    'primary-light': #00b0ff,
    'secondary': #007bb2,
    'secondary-dark': #00b0ff,
    'secondary-light': #33bfff,
  ),
  'status': (
    'warning': #f5b300,
    'error': #bd2f00,
    'success': #03632d,
    'selected': #1890ff,
  ),
  'neutral': (
    'white': #ffffff,
    'lighter': #d1d6de,
    'light': #bababa,
    'gray': #282c34,
    'dark': #101010,
    'darkest': #0d1007,
  ),
) !default;
