.banner {
  position: relative;
  height: 600px;
  width: 100%;
  box-shadow: 2px 2px 5px get-color('theme', 'primary');

  video {
    position: absolute;
    height: inherit;
    width: inherit;
    object-fit: cover;
    background-size: cover;
    transition: 1s opacity;
  }

  .banner-particles {
    position: absolute;
    width: 100%;
    height: inherit;
  }

  .banner-content {
    position: absolute;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 15px;

    .banner-title {
      h1 {
        font-family: $font-secondary;
        text-shadow: 1px 1px 5px get-color('theme', 'primary');
        text-align: center;
        color: get-color('neutral', 'white');
        margin-bottom: -15px;
      }

      span {
        font-family: $font-primary;
        color: get-color('theme', 'primary');
        margin-bottom: 0;
      }
    }
  }

  .stopfade {
    opacity: 0.5;
  }
}
