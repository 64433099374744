h2 {
  font-family: $font-primary;
  font-weight: 700;
}

h3 {
  font-family: $font-primary;
}

ul,
p,
span {
  font-family: $font-secondary;
}

