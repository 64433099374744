// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

// Rem Mixins
@mixin rem-fallback($property, $values...) {
  $max: length($values);
  $pxValues: '';
  $remValues: '';

  @for $i from 1 through $max {
      $value: strip-units(nth($values, $i));
      $pxValues: #{$pxValues + $value}px;

      @if $i < $max {
          $pxValues: #{pxValues + " "};
      }
  }

  @for $i from 1 through $max {
      $value: strip-units(nth($values, $i));
      $remValues: #{$remValues + (rem($value))};

      @if $i < $max {
          $remValues: #{$remValues + " "};
      }
  }

  #{$property}: $pxValues;
  #{$property}: $remValues;
}

// Responsive Mixins
@mixin respond-to($breakpoint) {
  @media (max-width: $breakpoint) {
      @content;
  }
}

// Font Mixins
@mixin font($family: $font-primary, $size: "base", $weight: 400, $line-height: none) {
  @include font-size($size);
  font-family: $family;
  font-weight: $weight;

  @if $line-height != none {
      @include line-height($line-height);
  }
}

@mixin font-size($size: "base") {
  @include rem-fallback(font-size, get-font-size($size));
}

// Box sizes

@mixin line-height($size) {
  @include rem-fallback(line-height, $size);
}

@mixin height($value) {
  @include rem-fallback(height, $value);
}

@mixin width($value) {
  @include rem-fallback(width, $value);
}

@mixin margin($values...) {
  @include rem-fallback(margin, $values...);
}

@mixin margin-bottom($value) {
  @include rem-fallback(margin-bottom, $value);
}

@mixin margin-left($value) {
  @include rem-fallback(margin-left, $value);
}

@mixin margin-right($value) {
  @include rem-fallback(margin-right, $value);
}

@mixin margin-top($value) {
  @include rem-fallback(margin-top, $value);
}

@mixin padding($values...) {
  @include rem-fallback(padding, $values...);
}

@mixin padding-bottom($value) {
  @include rem-fallback(padding-bottom, $value);
}

@mixin padding-left($value) {
  @include rem-fallback(padding-left, $value);
}

@mixin padding-right($value) {
  @include rem-fallback(padding-right, $value);
}

@mixin padding-top($value) {
  @include rem-fallback(padding-top, $value);
}
