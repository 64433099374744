body {
  margin: 0;

  h1,
  h2,
  h3,
  ul,
  p {
    color: get-color('neutral', 'white');
  }
}

.base-layout {
  min-height: 100vh;
  height: 100%;
  background: none;
  background-color: get-color('neutral', 'gray');
}

.page {
  padding: 25px 50px;

  .page-layout {
    background: radial-gradient(get-color('neutral', 'white'), get-color('neutral', 'lighter'));
    box-shadow: 2px 2px 5px get-color('theme', 'primary');
  }
}
