.navigation {
  z-index: 1;
  width: 100%;
  height: fit-content;
  background-color: transparent !important;
  transition: 1s ease;

  &.fixed {
    position: fixed;
  }

  &.scrolled {
    background: linear-gradient(
      to top left,
      get-color('theme', 'primary') 0%,
      get-color('neutral', 'dark') 25%
    ) !important;
    box-shadow: 2px 2px 5px get-color('theme', 'primary');
  }

  .ant-menu {
    font-family: $font-primary;
    background-color: transparent;

    .ant-menu-item-selected {
      background-color: transparent;
      color: get-color('theme', 'primary') !important;

      &::after {
        background-color: get-color('theme', 'primary');
        transform: scaleX(1) !important;
      }
    }

    .ant-menu-item {
      color: get-color('neutral', 'white');

      &::after {
        border-bottom: 3px solid transparent;
        transform: scaleX(0);
        transition: transform 250ms ease-in;
      }

      &:hover {
        background-color: transparent;

        &::after {
          background-color: get-color('theme', 'primary');
          border-bottom: 3px solid transparent;
          transform: scaleX(1);
          transform-origin: 0% 50%;
        }
      }
    }
  }

  .call-now {
    font-family: $font-primary;
    color: get-color('neutral', 'white');
  }

  .social-media {
    :last-child {
      padding-right: 0 !important;
    }

    .icon {
      @include font($font-primary, 18, 500, none);
      color: get-color('neutral', 'white');
      padding-right: 20px;

      &:hover {
        color: get-color('theme', 'primary');
        transition: 0.3s;
        cursor: pointer;
      }
    }
  }
}

.ant-layout-header {
  line-height: 45px;
}
