@include respond-to(get-media-size('computer')) {
  h2 {
    font-size: get-font-size('larger');
  }
  
  h3 {
    font-size: get-font-size('large');
  }

  ul,
  p {
    font-size: get-font-size('small');
  }

  .navigation {
    .ant-menu,
    .call-now {
      font-size: get-font-size('base');
    }
  }

  .banner {
    .banner-content {
      .banner-title {
        h1 {
          font-size: 58px;
          font-weight: 900px;
          line-height: 75px;
        }

        span {
          font-size: 32px;
          font-weight: 900px;
          line-height: 75px;
        }
      }
    }
  }

  .section {
    h2 {
      font-size: get-font-size('base');
    }

    .service-card {
      width: 400px;
    
      img {
        height: 250px;
        width: 400px;
      }

      .ant-card-body {
        padding: 12px;
      }
    }
  }
}

@include respond-to(get-media-size('tablet')) {
  h2 {
    font-size: get-font-size('large');
  }
  
  h3 {
    font-size: get-font-size('base');
  }

  ul,
  p {
    font-size: get-font-size('smaller');
  }

  .navigation {
    .ant-menu,
    .call-now {
      font-size: get-font-size('small');
    }
  }

  .banner {
    .banner-content {
      .banner-title {
        h1 {
          font-size: 48px;
          font-weight: 900px;
          line-height: 60px;
        }

        span {
          font-size: 28px;
          font-weight: 900px;
          line-height: 60px;
        }
      }
    }
  }

  .section {
    h2 {
      font-size: get-font-size('small');
    }

    .service-card {
      width: 400px;
    
      img {
        height: 250px;
        width: 400px;
      }

      .ant-card-body {
        padding: 12px;
      }
    }
  }
}

@include respond-to(get-media-size('phone')) {
  h2 {
    font-size: get-font-size('base');
  }
  
  h3 {
    font-size: get-font-size('small');
  }

  ul,
  p {
    font-size: get-font-size('smallest');
  }

  .navigation {
    .ant-menu,
    .call-now {
      font-size: get-font-size('smaller');
    }
  }

  .banner {
    .banner-content {
      .banner-title {
        h1 {
          font-size: 27px;
          font-weight: 900px;
          line-height: 50px;
        }

        span {
          font-size: 22px;
          font-weight: 900px;
          line-height: 50px;
        }
      }
    }
  }

  .section {
    h2 {
      font-size: get-font-size('smaller');
    }

    .service-card {
      width: 200px;
    
      img {
        height: 150px;
        width: 200px;
      }

      .ant-card-body {
        padding: 12px;

        h2 {
          font-size: get-font-size('smallest');
          line-height: unset;
        }
      }
    }
  }
}

@include respond-to(get-media-size('phone-xs')) {
  .banner {
    .banner-content {
      .banner-title {
        h1 {
          font-size: 23px;
          font-weight: 900px;
          line-height: 50px;
        }

        span {
          font-size: 18px;
          font-weight: 900px;
          line-height: 50px;
        }
      }
    }
  }
}